<template>
  <div class="infobox">
        <van-nav-bar
       title="航空附加险"
       left-arrow
       fixed
       placeholder 
       @click-left="onClickLeft"
    />
    <div v-html="center"></div>
  </div>
</template>
<script>
import * as InsuranceList from "@/utils/api";
export default {
  data() {
    return {
      center: "",
      active:'', // 返回路径的tab name
    };
  },
  methods:{
    onClickLeft(){
      if (this.active == 'claimDetails') {
        this.$router.back()
      }else{
       this.$router.push({
          name:"claimedit",
          query:{active:'a'}
        })
      }
 
    }
  },
  mounted() {
    InsuranceList.agreementApi("EXTRA_AIR_INSURANCE").then(res => {
      if (res.content) {
        this.center = res.content;
      }
    });
      this.active = this.$route.query.type
  }
};
</script>
<style lang="scss" scoped="true">
::v-deep{
     .van-nav-bar{
        height: 86px;
        .van-nav-bar__content{
        line-height: 86px;
        height: 86px;
          background: #39394D;
        .van-nav-bar__title{
            font-size: 36px;
            color: #f6d0ab;
        }
        .van-icon{
            font-size: 40px;
            color: #f6d0ab;
            right: 20px;
        }
        }
     }  
 }
.red {
  color: red;
}
.fb {
  font-weight: bold;
  font-size: 32px;
}
.titlebox {
  margin-top: 15px;
  font-weight: bold;
}
.infobox {
  line-height: 45px;
  font-size: 26px;
  margin: 30px 25px;
}
</style>
